<template>
  <div id="navbar">
    <nav class="navbar navbar-expand-xl navbar-light mx-auto">
      <button
        class="navbar-toggler ml-auto"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: isActiveList[0] }"
              href="#"
              @click.prevent="activeNav(0, '/')"
            >
              <span>{{ $t('GENERAL.NAV_OPTIONS[0]') }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: isActiveList[1] }"
              href="#"
              @click.prevent="activeNav(1, 'person_desp')"
            >
              <span>{{ $t('GENERAL.NAV_OPTIONS[1]') }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: isActiveList[2] }"
              href="#"
              @click.prevent="activeNav(2, 'website_desp')"
            >
              <span>{{ $t('GENERAL.NAV_OPTIONS[2]') }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: isActiveList[3] }"
              href="#"
              @click.prevent="activeNav(3, 'biography')"
            >
              <span>{{ $t('GENERAL.NAV_OPTIONS[3]') }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: isActiveList[4] }"
              href="#"
              @click.prevent="activeNav(4, 'treasure')"
            >
              <span>{{ $t('GENERAL.NAV_OPTIONS[4]') }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: isActiveList[5] }"
              href="#"
              @click.prevent="activeNav(5, 'visual_display')"
            >
              <span>{{ $t('GENERAL.NAV_OPTIONS[5]') }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: isActiveList[6] }"
              href="#"
              @click.prevent="activeNav(6, 'dbsearch')"
            >
              <span>{{ $t('GENERAL.NAV_OPTIONS[6]') }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: isActiveList[7] }"
              href="#"
              @click.prevent="activeNav(7, 'topic_research')"
            >
              <span>{{ $t('GENERAL.NAV_OPTIONS[7]') }}</span>
            </a>
          </li>
        </ul>
        <div class="nav-text ml-auto">
          <a
            href="#"
            :class="{ active: lang == 'zh' }"
            @click.prevent="setLang"
            data-lang="tw"
            >中</a
          >
          <span> | </span>
          <a
            href="#"
            :class="{ active: lang == 'en' }"
            @click.prevent="setLang"
            data-lang="en"
            >EN</a
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  data() {
    return {
      isActiveList: [1, 0, 0, 0, 0, 0, 0, 0],
      lang: 'zh',
    };
  },
  methods: {
    activeNav(index, path) {
      this.isActiveList = [0, 0, 0, 0, 0, 0, 0, 0];
      this.isActiveList[index] = 1;
      if (path === 'website_desp') {
        this.$router.push({ path });
        document.getElementById(path).scrollIntoView();
      } else if (path === 'person_desp') {
        this.$router.push({ path });
        document.getElementById(path).scrollIntoView();
      } else {
        this.$router.push({ path });
      }
    },
    setActiveLanguage(lang) {
      console.log('lang', lang);
      localStorage.setItem('language', lang);
    },
    setLang(evt) {
      const lang = evt.target.dataset.lang;
      this.setActiveLanguage(lang);
      return window.history.go(0);
    },
  },
};
</script>

<style lang="sass" scoped>
.nav-item
  font-size: 18px
  letter-spacing: 2px
  color: #595757
  font-weight: bold
  font-family: "Microsoft Jheng Hei Bold"

li
  display: inline-block
  a
    margin-right: 1.5rem
  a.active
    span
      border-bottom: 2px solid red
  a:hover
    span
      border-bottom: 2px solid red

.nav-text
  font-size: 18px
  letter-spacing: 2px
  font-weight: bold
  font-family: "Microsoft Jheng Hei Bold"
  a
    color: #595757
  a.active
    boder: 0
    color: #f15102
</style>
