<template>
  <footer>
    <div>
      <span>© 2020 中央研究院 中國文哲研究所 All rights reserved    【本網站獲科技部人文社會科學研究中心經費補助】</span>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
footer
  div
    background-color: #c9caca
    padding: 4px 0px
    font-size: 16px
    letter-spacing: 1px
    color: #595757
    font-weight: 300
    font-family: "Microsoft Jheng Hei Light"
    text-align: center
</style>
