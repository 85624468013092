<template>
  <div id="main">
    <button type="button" v-if="testMode" @click="showLog">test</button>
    <div class="container-md">
      <i-navbar ref="nav"></i-navbar>
    </div>
    <div>
      <div class="container-fluid">
        <div id="topbar" class="row">
          <div class="col">
            <span
              type="button"
              class="d-inline-block mx-3 my-1"
              @click="pageType = 'note'"
              :class="{ topbarActiveFont: pageType == 'note' }"
              >大事紀</span
            >
            <span
              type="button"
              class="d-inline-block mx-3 my-1"
              :class="{ topbarActiveFont: pageType == 'path' }"
              @click="changePageType('path')"
              >行跡圖</span
            >
            <span
              type="button"
              class="d-inline-block mx-3 my-1"
              :class="{ topbarActiveFont: pageType == 'network' }"
              @click="pageType = 'network'"
              >社會網絡圖</span
            >
          </div>
        </div>

        <div
          class="container-md my-3"
          :class="{ 'd-none': pageType != 'note' }"
        >
          <div id="noteEvent" class="mt-3">
            <div class="noteEventLine text-left">
              <div
                class="mt-5 mb-2 text-left p-3 eventSection d-inline-block mx-2"
                v-for="item in currEventList"
                :key="item.event"
                :class="{
                  eventTypePerson: item.eventType == '個人事件',
                  eventTypeImportant: item.eventType == '重大事件',
                  eventTypePublish: item.eventType == '出版事件',
                  eventTypeHistory: item.eventType == '歷史事件',
                  eventStyleOne: eventStyleOneIdList.includes(
                    parseInt(item.sortNum, 10),
                  ),
                  eventStyleTwo: eventStyleTwoIdList.includes(
                    parseInt(item.sortNum, 10),
                  ),
                  eventStyleOther:
                    !eventStyleOneIdList.includes(parseInt(item.sortNum, 10)) &&
                    !eventStyleTwoIdList.includes(parseInt(item.sortNum, 10)),
                }"
              >
                <div class="text-center">
                  <img
                    v-if="item.eventType == '個人事件'"
                    src="../assets/img/person_icon.png"
                    class="mt-3 img-icon"
                    alt="..."
                  />
                  <img
                    v-if="item.eventType == '重大事件'"
                    src="../assets/img/important_icon.png"
                    class="mt-3 img-icon"
                    alt="..."
                  />
                  <img
                    v-if="item.eventType == '出版事件'"
                    src="../assets/img/publish_icon.png"
                    class="mt-3 img-icon"
                    alt="..."
                  />
                  <img
                    v-if="item.eventType == '歷史事件'"
                    src="../assets/img/history_icon.png"
                    class="mt-3 img-icon"
                    alt="..."
                  />
                </div>
                <div class="mt-5">
                  <p class="date">{{ item.date }}</p>
                  <p></p>
                  <span class="age">{{ item.age }}</span
                  ><br />
                  <span
                    class="eventType"
                    :class="{
                      eventTypePersonTag: item.eventType == '個人事件',
                      eventTypeImportantTag: item.eventType == '重大事件',
                      eventTypePublishTag: item.eventType == '出版事件',
                      eventTypeHistoryTag: item.eventType == '歷史事件',
                    }"
                    >{{ item.eventType }}</span
                  ><br />
                  <span class="event">{{ item.event }}</span>
                  <br />
                  <div v-if="item.imageName != ''" class="text-center">
                    <img
                      v-if="item.imageName != ''"
                      :src="item.imageName"
                      class="img-image"
                      alt="..."
                    />
                    <!-- <div
                      class="img-image-person"
                      v-else-if="item.eventType == '個人事件'"
                    ></div>
                    <div
                      class="img-image-important"
                      v-else-if="item.eventType == '重大事件'"
                    ></div>
                    <div
                      class="img-image-publish"
                      v-else-if="item.eventType == '出版事件'"
                    ></div>
                    <div
                      class="img-image-history"
                      v-else-if="item.eventType == '歷史事件'"
                    ></div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 text-left eventTypeDesp">
              <span class="">事件類型</span>
              <div class="ml-3 d-inline-block">
                <button
                  type="button"
                  class="mx-1 btn eventTypeHistoryBtn text-white"
                  :class="{ conditionActive: currEventType == '歷史事件' }"
                  @click="updateCurrEventType('歷史事件')"
                >
                  歷史事件
                </button>
                <button
                  type="button"
                  class="mx-1 btn eventTypeImportantBtn text-white"
                  :class="{ conditionActive: currEventType == '重大事件' }"
                  @click="updateCurrEventType('重大事件')"
                >
                  重大事件
                </button>
                <button
                  type="button"
                  class="mx-1 btn eventTypePublishBtn text-white"
                  :class="{ conditionActive: currEventType == '出版事件' }"
                  @click="updateCurrEventType('出版事件')"
                >
                  出版事件
                </button>
                <button
                  type="button"
                  class="mx-1 btn eventTypePersonBtn text-white"
                  :class="{ conditionActive: currEventType == '個人事件' }"
                  @click="updateCurrEventType('個人事件')"
                >
                  個人事件
                </button>
              </div>
            </div>

            <div class="mt-3 row">
              <button
                type="button"
                class="mx-2 btn ageBtn col"
                :class="{ conditionActive: currAgeRange == 0 }"
                @click="updateCurrAgeRange(0)"
              >
                0歲
              </button>
              <button
                type="button"
                class="mx-2 btn ageBtn col"
                :class="{ conditionActive: currAgeRange == 10 }"
                @click="updateCurrAgeRange(10)"
              >
                10歲
              </button>
              <button
                type="button"
                class="mx-2 btn ageBtn col"
                :class="{ conditionActive: currAgeRange == 20 }"
                @click="updateCurrAgeRange(20)"
              >
                20歲
              </button>
              <button
                type="button"
                class="mx-2 btn ageBtn col"
                :class="{ conditionActive: currAgeRange == 30 }"
                @click="updateCurrAgeRange(30)"
              >
                30歲
              </button>
              <button
                type="button"
                class="mx-2 btn ageBtn col"
                :class="{ conditionActive: currAgeRange == 40 }"
                @click="updateCurrAgeRange(40)"
              >
                40歲
              </button>
              <button
                type="button"
                class="mx-2 btn ageBtn col"
                :class="{ conditionActive: currAgeRange == 50 }"
                @click="updateCurrAgeRange(50)"
              >
                50歲
              </button>
              <button
                type="button"
                class="mx-2 btn ageBtn col"
                :class="{ conditionActive: currAgeRange == 60 }"
                @click="updateCurrAgeRange(60)"
              >
                60歲
              </button>
            </div>

            <span class="text-left d-block mt-3 font-weight-bold">時間軸</span>
            <div class="mt-1 row">
              <div class="col">1863</div>
              <div class="col">1873</div>
              <div class="col">1883</div>
              <div class="col">1893</div>
              <div class="col">1903</div>
              <div class="col">1913</div>
              <div class="col">1923</div>
            </div>
          </div>
        </div>

        <div
          class="container-md my-3"
          :class="{ 'd-none': pageType != 'network' }"
        >
          <iframe
            class="w-100 vh-100"
            src="https://embed.kumu.io/5e85102eb593eb00cd08072b3f259610#"
          ></iframe>
        </div>

        <div
          class="container-md my-3"
          :class="{ 'd-none': pageType != 'path' }"
        >
          <div class="row">
            <!-- timeline -->
            <div id="timeline" class="col-3">
              <div class="time_scale mt-2 row">
                <span class="d-block col-4"> 年代 </span>
                <span class="d-block col-4"> </span>
                <span class="d-block col-4"> 事件編號 </span>
              </div>
              <div
                class="time_scale mt-2 row"
                v-for="item in pathEventList"
                :key="item.eventId"
              >
                <span class="d-block col-4"> {{ item.year }} </span>
                <!-- <span class="d-block col-4"> x </span> -->
                <input
                  :id="'event_' + item.eventId"
                  type="checkbox"
                  class="d-block col-4 mt-1"
                />
                <span class="d-block col-4"> {{ item.eventId }} </span>
              </div>
            </div>
            <!-- map -->
            <div class="col-9">
              <div
                class="p-2"
                id="eventBox"
                :class="{
                  visableDiv: eventBoxVisable,
                  noneVisableDiv: !eventBoxVisable,
                }"
              >
                <a
                  href="#"
                  class="float-right"
                  @click.prevent="eventBoxVisable = !eventBoxVisable"
                >
                  <i
                    v-if="eventBoxVisable"
                    class="fa fa-eye-slash"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-eye" aria-hidden="true"></i>
                </a>
                <p>事件編號：{{ currEvent.eventId }}</p>
                <hr />
                <p>年份：{{ currEvent.year }}</p>
                <p>歲數：{{ currEvent.age }}</p>
                <p>地點：{{ currEvent.loc }}</p>
                <p>描述</p>
                <div class="">{{ currEvent.description }}</div>
              </div>
              <div
                id="my-map"
                class="vh-100"
                v-show="pathPageType == 'path'"
              ></div>
              <img
                class="w-100"
                :src="currEvent.imgUrl"
                v-show="pathPageType == 'city'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      id="go_back_btn"
      type="button"
      class="btn btn-info"
      v-show="pathPageType != 'path'"
      @click="pathPageType = 'path'"
    >
      回到行跡圖
    </button>
    <i-footer></i-footer>
  </div>
</template>

<script>
/* global L */
/* eslint-disable global-require */
/* eslint no-underscore-dangle: 0 */
/* eslint-disable */

import $ from 'jquery';
import navbar from '../components/Navbar.vue';
import footer from '../components/Footer.vue';

export default {
  mounted() {
    this.$refs.nav.isActiveList = [0, 0, 0, 0, 0, 0, 0, 0];
    this.$refs.nav.isActiveList[5] = 1;

    this.initialMap();

    this.updateEventList();

    this.updateEventPathList();
  },
  components: {
    'i-navbar': navbar,
    'i-footer': footer,
  },
  data() {
    return {
      testMode: false,
      pageType: 'note',
      pageTypeList: ['note', 'path', 'network'],
      pathPageType: 'path',
      pathPageTypeList: ['path', 'city'],
      currEventType: null,
      currAgeRange: null,
      currEventList: [],
      eventList: [],
      myMap: null,
      currMarkerList: [],
      eventPath: null,
      pathLatLngs: [],
      pathEventList: [],
      currPathEventList: [],
      currEvent: {
        eventId: '',
        year: '',
        age: '',
        loc: '',
        description: '',
        imgUrl: '',
      },
      eventBoxVisable: true,
      eventStyleOneIdList: [
        1, 2, 3, 4, 5, 8, 11, 15, 17, 19, 21, 22, 25, 28, 29, 30, 31, 32, 34,
        35, 36, 37, 39, 40, 41, 52, 53, 54, 56, 57, 63, 64, 67, 68, 69, 70, 71,
        77, 78, 91, 92, 94, 95, 102, 110, 114, 119, 122, 123, 128, 130, 133,
        139, 143, 146, 147, 148, 149, 150, 160, 161, 162, 171, 172,
      ],
      eventStyleTwoIdList: [154, 155],
    };
  },
  methods: {
    showLog() {
      this.updateCurrEvent();
    },
    updateEventPathList() {
      const api = `${process.env.VUE_APP_APIPATH}api/event-path-list/`;
      this.$http
        .get(api)
        .then((res) => {
          // console.log(res);
          this.pathEventList = res.data;
          this.pluginPathMapJquery();
          this.pointEventMarker();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateCurrEvent() {
      // console.log(this.currPathEventList[this.currPathEventList.length - 1]);
      this.currEvent = {};
      if (this.currPathEventList.length > 0) {
        this.currEvent =
          this.currPathEventList[this.currPathEventList.length - 1];
      }
    },
    drawEventPath() {
      if (this.eventPath) {
        this.myMap.removeLayer(this.eventPath);
      }
      this.pathLatLngs = [];
      let theLastPoint = -1;
      this.currPathEventList.forEach((item) => {
        this.pathLatLngs.push(item.locXY);
        theLastPoint = item.locXY;
      });
      // draw lines
      // create a red polyline from an array of LatLng points
      this.eventPath = L.polyline(this.pathLatLngs, { color: 'tomato' });
      this.myMap.addLayer(this.eventPath);

      // zoom the map to the polyline
      // this.myMap.fitBounds(this.eventPath.getBounds());
      this.myMap.setView(theLastPoint);
    },
    pointEventMarker() {
      const redPointIcon = L.icon({
        iconUrl: require('../assets/img/redPointIcon.png'),
        iconSize: [15, 15],
        iconAnchor: [5, 5],
        popupAnchor: [0, -18],
        labelAnchor: [14, 0], // as I want the label to appear 2px past the icon (18 + 2 - 6)
      });

      // clear marker
      this.currMarkerList.forEach((item) => {
        this.myMap.removeLayer(item);
      });
      this.currMarkerList = [];

      // draw marker
      this.currPathEventList.forEach((item) => {
        const marker = L.marker(item.locXY, {
          icon: redPointIcon,
        })
          .bindTooltip(`${item.eventId}. ${item.loc}`, { permanent: true })
          .addTo(this.myMap)
          .on('click', () => {
            console.log(`eventId: ${item.eventId}`);
            this.pathPageType = 'city';
          });
        marker.openTooltip();
        marker._markerId = item.eventId;
        this.currMarkerList.push(marker);
      });
    },
    initialMap() {
      this.myMap = L.map('my-map').setView([34.6559539, 112.3080747], 5);
      L.tileLayer(
        'http://gis.sinica.edu.tw/ccts/file-exists.php?img=ad1820-png-{z}-{x}-{y}',
        {
          maxZoom: 14,
          attribution:
            'Map data: © <a href="http://gis.sinica.edu.tw/ccts/" target="_blank">中研院地理資訊中心</a>',
        },
      ).addTo(this.myMap);
    },
    changePageType(pageType) {
      this.pageType = pageType;
      setTimeout(() => {
        this.myMap.invalidateSize();
      }, 200);
    },
    pluginPathMapJquery() {
      $(document).ready(() => {
        $('input').on('click', (e) => {
          const eventId = e.target.id;
          const checkboxId = `#${eventId}`;
          console.log(eventId, $(checkboxId).prop('checked'));
          this.currPathEventList = [];

          if ($(checkboxId).prop('checked') === true) {
            // reset all checkbox and currPathEventList
            $('input').prop('checked', false);

            const currId = parseInt(eventId.split('_')[1], 10);
            // console.log(currId);

            // set true before currId ( contains currId )
            for (let i = 1; i <= currId; i += 1) {
              $(`#event_${i}`).prop('checked', true);
              this.currPathEventList.push(this.pathEventList[i - 1]);
            }
          } else {
            // reset all checkbox and currPathEventList
            $('input').prop('checked', false);

            const currId = parseInt(eventId.split('_')[1], 10);
            // console.log('xxx', currId);

            // set true before currId ( contains currId )
            for (let i = 1; i < currId; i += 1) {
              $(`#event_${i}`).prop('checked', true);
              this.currPathEventList.push(this.pathEventList[i - 1]);
            }
          }
          this.pointEventMarker();
          this.drawEventPath();
          this.updateCurrEvent();
        });
      });
    },
    updateEventList() {
      const eventListAPI = `${process.env.VUE_APP_APIPATH}api/event-list/`;
      this.$http.get(eventListAPI).then((response) => {
        // console.log(response.data);
        this.eventList = response.data;
        this.eventList.forEach((item, index) => {
          if (item.imageName !== '') {
            this.eventList[index].imageName = encodeURI(
              `${process.env.VUE_APP_IMAGE_APIPATH}visual_display/${item.imageName}`,
            );
          }
        });
        this.updateCurrEventList(null);
      });
    },
    updateCurrAgeRange(ageRange) {
      if (this.currAgeRange === ageRange) {
        this.currAgeRange = null;
      } else {
        this.currAgeRange = ageRange;
      }
      this.updateCurrEventList();
    },
    updateCurrEventType(eventType) {
      if (this.currEventType === eventType) {
        this.currEventType = null;
      } else {
        this.currEventType = eventType;
      }
      this.updateCurrEventList();
    },
    updateCurrEventList() {
      if (this.currEventType === null && this.currAgeRange === null) {
        this.currEventList = [...this.eventList];
      } else if (this.currEventType !== null && this.currAgeRange === null) {
        this.currEventList = [];
        this.eventList.forEach((item) => {
          if (item.eventType === this.currEventType) {
            this.currEventList.push(item);
          }
        });
      } else if (this.currAgeRange !== null && this.currEventType === null) {
        this.currEventList = [];
        this.eventList.forEach((item) => {
          const ageStart = this.currAgeRange;
          const ageEnd = this.currAgeRange + 10;
          let { age } = item;
          if (age === '周歲') {
            age = '1歲';
          }
          age = age.replace('歲', '');
          age = parseInt(age, 10);
          if (age >= ageStart && age < ageEnd) {
            this.currEventList.push(item);
          }
        });
      } else if (this.currEventType !== null && this.currAgeRange !== null) {
        this.currEventList = [];
        this.eventList.forEach((item) => {
          // filter by event
          const isEvent = item.eventType === this.currEventType;

          // filter by age range
          const ageStart = this.currAgeRange;
          const ageEnd = this.currAgeRange + 10;
          let { age } = item;
          if (age === '周歲') {
            age = '1歲';
          }
          age = age.replace('歲', '');
          age = parseInt(age, 10);
          const isInAge = age >= ageStart && age < ageEnd;
          console.log(age, isInAge);

          if (isEvent && isInAge) {
            this.currEventList.push(item);
          }
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.eventStyleOne
  width: 260px

.eventStyleTwo
  width: 250px

.eventStyleOther
  width: 180px

#topbar
  background-color: #9fcfce
  font-size: 14px
  letter-spacing: 1px
  color: #595757
  font-family: "Microsoft Jheng Hei Regular"
  .span_section
    padding-right: 5.4rem

.topbarActiveFont
  color: #296464
  font-weight: bold

.noteEventLine
  height: 600px
  overflow-y: hidden
  overflow-x: auto
  white-space: nowrap

.eventSection
  position: relative
  height: 475px
  border-radius: 5px
  font-size: 14px
  font-weight: bold
  letter-spacing: 1px
  line-height: 20px
  color: #3e3a39
  font-family: "Microsoft Jheng Hei Regular"
  white-space: normal
  vertical-align: top
  .img-image
    position: absolute
    left: 35px
    bottom: 10px

.eventTypeDesp
  font-size: 16px
  font-weight: bold
  letter-spacing: 2px
  color: #595757
  font-family: "Microsoft Jheng Hei Regular"

.img-icon
  width: 50px
  height: 50px

.img-icon-hidden
  width: 50px
  height: 50px
  opacity: 0

.img-image
  width: 190px
  height: 120px

.img-image-person
  height: 120px
  background-image: url('../assets/img/person_icon.png')
  background-position: center center
  background-repeat: no-repeat

.img-image-important
  height: 120px
  background-image: url('../assets/img/important_icon.png')
  background-position: center center
  background-repeat: no-repeat

.img-image-publish
  height: 120px
  background-image: url('../assets/img/publish_icon.png')
  background-position: center center
  background-repeat: no-repeat

.img-image-history
  height: 120px
  background-image: url('../assets/img/history_icon.png')
  background-position: center center
  background-repeat: no-repeat

.eventTypePerson
  background-color: #edd9bc
.eventTypePersonTag
  color: #f59205
.eventTypeImportant
  background-color: #bde0df
.eventTypeImportantTag
  color: #08b1b3
.eventTypePublish
  background-color: #d7dbcb
.eventTypePublishTag
  color: #8a9a54
.eventTypeHistory
  background-color: #bcd0d9
.eventTypeHistoryTag
  color: #02639a

.eventTypeHistoryBtn
  border-radius: 3px
  filter: drop-shadow(1.414px 1.414px 2.5px rgba(0,0,0,0.43))
  background-color: #02639a

.eventTypeImportantBtn
  border-radius: 3px
  filter: drop-shadow(1.414px 1.414px 2.5px rgba(0,0,0,0.43))
  background-color: #08b1b3

.eventTypePublishBtn
  border-radius: 3px
  filter: drop-shadow(1.414px 1.414px 2.5px rgba(0,0,0,0.43))
  background-color: #8a9a54

.eventTypePersonBtn
  border-radius: 3px
  filter: drop-shadow(1.414px 1.414px 2.5px rgba(0,0,0,0.43))
  background-color: #f59205

.ageBtn
  padding: 2px 0px
  background-color: #E8E8E8
  color: darkgreen

.conditionActive
  border: solid 2px red

#eventBox
  position: absolute
  z-index: 99999
  background-color: #E4EDEE
  width: 25%
  text-align: left
  right: 25px
  top: 10px

#go_back_btn
  position: fixed
  right: 0
  bottom: 0

#timeline
  height: 100vh
  overflow-y: auto
  overflow-x: hidden

.visiableDiv
  opacity: 1

.noneVisableDiv
  opacity: 0.4
</style>
